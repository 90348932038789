import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

const routes: Routes = [
  {
    path: 'login',
    loadChildren: './views/login/login.module#LoginModule'
  },
  {
    path: 'dashboard',
    loadChildren: './views/dashboard/dashboard.module#DashboardModule'
  },
  {
    path: 'maintenance',
    loadChildren: './views/maintenance/maintenance.module#MaintenanceModule'
  },
  { path: '**', pathMatch: 'full', redirectTo: 'login' }
];

@NgModule({
  imports: [RouterModule.forRoot(routes, {
    useHash: true,
    // onSameUrlNavigation: 'reload',
    // enableTracing: true
  })],
  exports: [RouterModule],
  providers: []
})
export class AppRoutingModule {}
