import { NgModule } from '@angular/core';
import { ExtraOptions, RouterModule, Routes } from '@angular/router';

const routes: Routes = [
    {
        path: 'login',
        loadChildren: () =>
            import('./views/login/login.module').then((m) => m.LoginModule),
    },
    {
        path: 'dashboard',
        loadChildren: () =>
            import('./views/dashboard/dashboard.module').then(
                (m) => m.DashboardModule,
            ),
    },
    {
        path: 'maintenance',
        loadChildren: () =>
            import('./views/maintenance/maintenance.module').then(
                (m) => m.MaintenanceModule,
            ),
    },
    { path: '**', pathMatch: 'full', redirectTo: 'login' },
];

const routerOptions: ExtraOptions = {
    useHash: true,
};

@NgModule({
    imports: [RouterModule.forRoot(routes, routerOptions)],
    exports: [RouterModule],
})
export class AppRoutingModule {}
