import { Component } from '@angular/core';
import { NavigationCancel, NavigationEnd, NavigationError, NavigationStart, Router, RouterEvent } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { filter } from 'rxjs/operators';
import { version } from '../../package.json';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {
  isLoading = false;
  title = 'Sonar';
  version = version;
  window: any;

  constructor(
    private router: Router,
    private translate: TranslateService,
  ) {
    this.translate.setDefaultLang('en');

    this.router.events
      .pipe(
        filter(
          (event: RouterEvent) =>
            event instanceof NavigationStart ||
            event instanceof NavigationEnd ||
            event instanceof NavigationCancel ||
            event instanceof NavigationError
        )
      )
      .subscribe((event: RouterEvent) => {
        this.isLoading = event instanceof NavigationStart;
      });
  }
}
