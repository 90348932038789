import {Injectable} from '@angular/core';
import {Token} from '../../../core/models/token/token';
import {HttpClient, HttpHeaders, HttpParams} from '@angular/common/http';
import {environment} from '../../../../environments/environment';
import {Observable} from 'rxjs/Observable';
import 'rxjs/add/operator/map';
import 'rxjs/add/operator/catch';
import 'rxjs/add/observable/throw';
import {throwError, EMPTY} from 'rxjs';
import { map, catchError } from 'rxjs/operators';

const UID = 'uid';
const JWT = 'jwt';
const OAUTH = 'oauth';
const HEADERS = new HttpHeaders().append("Content-Type", "application/x-www-form-urlencoded");


@Injectable()
export class TokenService {

    static readonly USER_TOKEN = 'userToken';
    static readonly URL_GET = `${environment.serviceUrl}/oauth/token?grant_type=password`;
    static readonly URL_REFRESH = `${environment.serviceUrl}/oauth/token?grant_type=refresh_token`;
    static readonly ENCODED_CLIENT = btoa(`${environment.clientId}:${environment.clientSecret}`);
    static readonly AUTHORIZATION_HEADER_VALUE = `Basic ${TokenService.ENCODED_CLIENT}`;
    static readonly AUTHORIZATION_HEADER = new HttpHeaders({'Authorization': TokenService.AUTHORIZATION_HEADER_VALUE});
    extractedUrl: string;
    
    
    constructor(
        private http: HttpClient
    ) {
    }

    setToken(token: Token): void {
        sessionStorage.setItem(TokenService.USER_TOKEN, JSON.stringify(token));
    }

    getToken(): string {
        return sessionStorage.getItem(JWT);
    }

    getUid() {
        return sessionStorage.getItem(UID);
    }

    getOauth2Token(){
        return sessionStorage.getItem(OAUTH);
    }

    removeToken(): void {
        sessionStorage.removeItem(TokenService.USER_TOKEN);
    }

    refreshToken(): Observable<any> {
        return EMPTY;
    }

    getRemoteToken(username: string, password: string): Observable<any> {
        const url = `${TokenService.URL_GET}&username=${username}&password=${password}`;
        let acquisitionTime: number;
        return this.http.post(url, null, {headers: TokenService.AUTHORIZATION_HEADER}).map(res => {
            acquisitionTime = new Date().getTime();
            let formatRes: object = res;
            formatRes = Object.assign({'acquisitionTime': acquisitionTime}, formatRes);
            this.setToken(formatRes as Token);
        }).catch(error => {
            return throwError(error);
        });
    }

    isTokenExpired(): boolean {
        return false;
    }

    saveExtractedUrl(extractedUrl: string) {
        this.extractedUrl = extractedUrl;
    }

    getOauth2TokenApi(): Observable<any>{

        let OAUTH2_URLTOKEN: string;

        if(environment.serviceUrl.includes("staging")){
            OAUTH2_URLTOKEN = environment.serviceUrl.replace("/Sonar/staging", "/oauthv2/cors/token");
        }
        else if(environment.serviceUrl.includes("maintenance")){
            OAUTH2_URLTOKEN = environment.serviceUrl.replace("/Sonar/maintenance", "/oauthv2/cors/token");
        }
        else{
            OAUTH2_URLTOKEN = environment.serviceUrl.replace("/Sonar", "/oauthv2/cors/token");
        }
        
        let headers = HEADERS;
        const body = new HttpParams()
        .set("client_id", environment.oauth2ClientId)
        .set("client_secret", environment.oauth2ClientSecret)
        .set("grant_type", "client_credentials");
        return this.http.post(OAUTH2_URLTOKEN, body, {
            headers: headers,
            observe: "response",
          }).pipe(
            map((res: any) => res),
            catchError((err) => throwError(err))
          )
    }
}
